import { Component, OnInit, ViewChild } from '@angular/core';
import { MailService } from 'src/app/services/mail.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { trigger, state, style, animate, transition, } from '@angular/animations';
@Component({
  selector: 'app-home-new',
  templateUrl: './home-new.component.html',
  styleUrls: ['./home-new.component.scss'],
  animations: [
    trigger('openClose', [
      state('closed', style({
        'height': '0px',
      })),
      state('open', style({
        'max-height': '800px',
      })),
      transition('open <=> closed', animate(400)),
    ])
  ]
})
export class HomeNewComponent implements OnInit {
  images = ['assets/gallery1.jpg', 'assets/gallery2.jpg', 'assets/gallery3.jpg'];
  currentIndex = 0;
  isAnimating = false;
  intervalId: any;

  get previousIndex() {
    return (this.currentIndex - 1 + this.images.length) % this.images.length;
  }

  nextImage() {
    if (this.isAnimating) return;

    this.isAnimating = true;

    setTimeout(() => {
      // Završava animaciju i prelazi na sledeću sliku
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
      this.isAnimating = false;
    }, 300); // Trajanje animacije
  }

  goToImage(index) {
    this.currentIndex = index;
    this.resetInterval(); // Resetovanje intervala
  }

  startInterval() {
    this.intervalId = setInterval(() => this.nextImage(), 5000); // Pokreće interval
  }

  resetInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId); // Zaustavlja trenutni interval
    }
    this.startInterval(); // Ponovo kreira novi interval
  }

  ngOnInit() {
    this.startInterval(); 
  }
}
