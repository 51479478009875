<div class="slider">
    <div
      class="image current"
      [style.background-image]="'url(' + images[currentIndex] + ')'"
    ></div>
    <div
      class="image previous"
      *ngIf="isAnimating"
      [style.background-image]="'url(' + images[previousIndex] + ')'"
    ></div>
    <div class="hero">
        <div class="text">
            <h2>«Your Value in Life»</h2>
        </div>
        <div class="dots">
            <div class="dot" [ngClass]="{'active': currentIndex === 0}" (click)="goToImage(0)"></div>
            <div class="dot" [ngClass]="{'active': currentIndex === 1}" (click)="goToImage(1)"></div>
            <div class="dot" [ngClass]="{'active': currentIndex === 2}" (click)="goToImage(2)"></div>
        </div>
    </div>

    <div class="box">
        <div class="box-text">
            <h3>Sie haben eine Frage?</h3>
            <p>Wir freuen uns auf Ihren Anruf.</p>
        </div>
        <button>Mehr erfahren</button>
    </div>
  </div>

  <div class="container">
    <div class="center-wrapper">
        <h2>Starte deine Karriere bei SSM</h2>
        <p>Stell dich neuen Herausforderungen, wachse mit uns und inspiriere andere 
            durch starke Werte und Visionen.</p>
    </div>

    <div class="card-wrapper">
        <div class="card">
            <img src="assets/gallery4.jpg" alt="">
            <div class="card-section">
                <h4>Räumlichkeiten</h4>
                <button>Entdecken</button>
            </div>
        </div>
        <div class="card">
            <img src="assets/gallery5.jpg" alt="">
            <div class="card-section">
                <h4>Finanzcoach</h4>
                <button>Jetzt bewerben</button>
            </div>
        </div>
    </div>

  </div>

<div class="section-wrapper">
    <div class="container">
        <div class="section">
            <div class="left">
                <h4>Haben Sie eine Frage?</h4>
                <h3><b>Unser Headquarter</b> in
                    Rothenburg, beantwortet gerne
                    Ihre offenen Fragen.
                    <br><br>
                    Komm vorbei und wir offerieren
                    dir gerne ein Kaffee.</h3>
                    <button>Mehr erfahren</button>
            </div>
            <div class="right">
                <img src="assets/gallery6.jpg" alt="">
            </div>
        </div>
    </div>
</div>